<template>
  <div class="home" style="height: 100%">
    <el-container style="height: 100%">
      <el-aside :width="sideWidth + 'px'" style="background-color: rgb(238, 241, 246); height: 100%">
        <Aside :isCollapse="isCollapse" />
      </el-aside  >

      <el-container>
        <el-header  style=" border-bottom: 1px solid #ccc; ">
          <Header :collapse-btn-class="collapseBtnClass" :collapse="collapse"/>
        </el-header>

        <el-main>
          <router-view/>
        </el-main>
      </el-container>
    </el-container>

  </div>
</template>

<script>
import Aside from "@/components/Aside.vue";
import Header from "@/components/Header.vue"
import User from "@/views/User.vue"
export default {
  data() {
    return {
      sideWidth:200,
      collapseBtnClass:'el-icon-s-fold',
      isCollapse:false
    }
  },
  components:{
    Aside,
    Header,
    User
  },

  methods:{
    collapse(){
      this.isCollapse = !this.isCollapse
      if(this.isCollapse){
        this.sideWidth = 64
        this.collapseBtnClass = 'el-icon-s-unfold'
      }else{
        this.sideWidth = 200
        this.collapseBtnClass = 'el-icon-s-fold'
      }
    }
  }
};
</script>

<style>
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
.headerBg{
  background-color: #eee !important;
}
</style>
