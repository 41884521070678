
<template>
  <div style="font-size: 12px; line-height:60px; display: flex">
    <div style="flex:1;font-size:18px">
      <span :class="collapseBtnClass" style="cursor:pointer; font-size: 20px" @click="collapse"></span>

<!--      <el-breadcrumb separator="/" style="display: inline-block; margin-left: 10px; ">-->
<!--        <el-breadcrumb-item :to="item.path" v-for="item in paths">{{item.name}}}">首页</el-breadcrumb-item>-->
<!--      </el-breadcrumb>-->
    </div>
    <el-dropdown style="width: 150px; cursor: pointer; text-align: right">
      <div style="display: inline-block">
        <img src="../assets/logo.png" alt="" style="width: 30px; border-radius: 50%; position: relative; top: 10px; right: 5px">
        <span>{{ user.username }}</span><i class="el-icon-arrow-down" style="margin-left: 5px"></i>
      </div>

        <el-dropdown-menu slot="dropdown" style="width: 100px; text-align: center">
          <el-dropdown-item style="font-size: 10px; padding: 5px 0">
            <span  style="text-decoration: none" @click="getUserInfo">个人信息</span>
          </el-dropdown-item>
          <el-dropdown-item style="font-size: 10px; padding: 5px 0">
            <span  style="text-decoration: none" @click="logout">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>

    </el-dropdown>
  </div>
</template>
  <script>
  import {removeToken} from "@/utils/auth";
  import {getCodeImg, getInfo, logout} from "@/api/login";

  export default{
    name:"Header",
    props:{

      collapseBtnClass: String,
      collapse:Function
    },
    data(){
      return{
        user:localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):{},
        paths:[]
      }
    },
    methods:{
      logout(){
        removeToken()
        this.$router.push("/login")
        logout().then(res=>{
          console.log(res)
          this.$message.success("退出成功")
        })

      },
      getUserInfo() {
        this.request.get("/auth/info").then(res => {
          console.log(res)
        })
      },
    }
  }
  </script>
<style>

</style>
