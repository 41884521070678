import axios from 'axios'
import ElementUI, {Message} from "element-ui";
import {getToken, removeToken} from '@/utils/auth'
import router from "@/router";

const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 20000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.headers['Content-Type'] = 'application/json'
        return config
    },
    error => {
        Promise.reject(error)
    }
)

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        if(res.code === 401){
            console.log(res.msg)
            removeToken()
            Message.error('登录验证失败，3秒后跳转登录页面');
            setTimeout(() => {
                router.push({ path: '/login' })
            }, '6000');

        }
        return res;
    },
    error => {
        console.log('err ' + error) // for debug
        return Promise.reject(error)
    }
)


export default request

