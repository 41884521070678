
<template>
  <el-menu :default-openeds="['1', '3']" style="min-height: 100%; overflow-x:hidden"
           background-color="rgb(48,65,86)"
           text-color="#fff"
           active-text-color="#ffd04b"
           :collapse-transition="false"
           :collapse="isCollapse"
           router
           @select="handleSelect"
  >
    <div style="height: 60px; line-height: 60px; text-align: center; ">
      <img src="../assets/logo.png" alt="" style="width: 20px; position: relative; top:5px; margin-right: 5px">
      <b style="color:white" v-show="!isCollapse">速必达</b>
    </div>
    <el-menu-item index="/">
      <template slot="title">
        <i class="el-icon-house"></i>
        <span slot="title">首页</span>
      </template>
    </el-menu-item>
    <el-menu-item index="/user" v-if="userManage">
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span slot="title">系统管理</span>
      </template>
    </el-menu-item>
    <el-menu-item index="/amazonShop">
      <template slot="title">
        <i class="el-icon-s-shop"></i>
        <span slot="title">店铺管理</span>
      </template>
    </el-menu-item>
    <el-menu-item index="/amazonProduct">
      <template slot="title">
        <i class="el-icon-s-goods"></i>
        <span slot="title">跟卖列表</span>
      </template>
    </el-menu-item>
    <el-menu-item index="/amazonNotify">
      <template slot="title">
        <i class="el-icon-s-data"></i>
        <span slot="title">调价列表</span>
      </template>
    </el-menu-item>

  </el-menu>
</template>


<script>
    export default {
      data(){
        return{
          user:localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")): {},
          userManage:false
        }

      },
      name:"Aside",
      props: {
        isCollapse:Boolean
      },
      methods:{
        handleSelect(){
          console.log(this.$router.options.routes)
        }
      },
      created() {
        this.userManage = this.user.username === 'admin';
      }
    }


</script>



<style scoped>

</style>
