

<template>
  <div>


    <div style="padding: 10px 0">
      <el-input style="width: 200px" suffix-icon="el-icon-search" placeholder="请输入名称" v-model="username"></el-input>
      <el-input style="width: 200px" suffix-icon="el-icon-edit" placeholder="请输入邮箱" class="ml-5"></el-input>
      <el-input style="width: 200px" suffix-icon="el-icon-edit" placeholder="请输入地址" class="ml-5"></el-input>
      <el-button class="ml-5" type="primary" @click="searchusers">搜索</el-button>
      <el-button class="ml-5" type="warning" @click="rset">重置</el-button>
    </div>
    <div style="margin: 10px 0">
      <el-button type="primary" @click="openadd">新增<i class="el-icon-circle-plus-outline"></i></el-button>

    </div>
    <el-table :data="tableData" border :header-cell-class-name-class-name="headerBg" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column prop="userId" label="ID" width="140">
      </el-table-column>
      <el-table-column prop="username" label="姓名" width="120">
      </el-table-column>
      <el-table-column prop="nickName" label="昵称">
      </el-table-column>
      <el-table-column prop="gender" :formatter="genderFormatter" label="性别">
      </el-table-column>
      <el-table-column prop="phone" label="电话">
      </el-table-column>
      <el-table-column prop="email" label="邮箱">
      </el-table-column>
      <el-table-column prop="isAdmin" :formatter="adminFormatter" label="管理员">
      </el-table-column>
      <el-table-column prop="enabled" :formatter="statusFormatter" label="状态">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="success" @click="updateUserInfo(scope.row)">编辑<i class="el-icon-edit"></i></el-button>
          <el-popconfirm
              class="ml-5"
              confirm-button-text='好的'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="你确定要删除该内容吗？"
              @confirm="delUserInfo(scope.row.userId)"
          >
            <el-button slot="reference" type="danger" @click="">删除<i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change = "handleSizeChange"
          @current-change = "handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="用户信息" :visible.sync="dialogFormVisible" width="30%">
      <el-form label-width="80px" size="small">
        <el-form-item label="用户名" >
          <el-input v-model="form.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" >
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="昵称" >
          <el-input v-model="form.nickName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别" >
          <el-input v-model="genderDisplayValue"  autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" >
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" >
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="saveUserInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getUser, userDel, userSave} from "@/api/user";
  import Cookies from 'js-cookie';

  export default {
    name:"User",
    data(){
      return{
        collapseBtnClass:'el-icon-s-fold',
        isCollapse:false,
        sideWidth:200,
        headerBg:'headerBg',
        total:0,
        pageNum:1,
        pageSize:5,
        username:"",
        dialogFormVisible:false,
        form:{},
        multipleSelection: [],
        tableData:[]
      }
    },
    created() {
      this.load()
    },
    computed: {
      genderDisplayValue: {
        // 当需要显示这个值的时候，会调用getter
        get() {
          // 这里可以对rawValue进行转换，转成显示的格式
          return this.form.gender?'男':'女';
        },
        // 当input的值改变时，会调用setter
        set(value) {
          // 更新rawValue
          this.form.gender = value === '男'
        }
      },
      genderFormatter() {
        return (row) => {
          switch (row.gender) {
            case true:
              return '男';
            case false:
              return '女';
            default:
              return '';
          }
        };
      },
      adminFormatter() {
        return (row) => {
          switch (row.isAdmin) {
            case true:
              return '是';
            case false:
              return '否';
            default:
              return '';
          }
        };
      },
      statusFormatter() {
        return (row) => {
          switch (row.enabled) {
            case true:
              return '正常';
            case false:
              return '禁用';
            default:
              return '';
          }
        };
      },
    },
    methods:{
      load() {
        getUser(this.pageNum,this.pageSize,this.username).then(res => {
          if(res.code === 200){
            this.tableData = res.data.records
            this.total = res.data.total
          }else if (res.code === 401){
            this.$router.push({ path: '/login' })
            this.$message.error(res.msg)
          }else{
            this.$message.error(res.msg)
          }

        })
      },
      saveUserInfo(){
        userSave(this.form).then(res => {
          if(res.code === 200){
            this.$message.success("保存成功！")
            this.dialogFormVisible = false
            this.form = {}
            this.load()
          }else{
            this.$message.success("保存失败！")
          }
        })
      },
      updateUserInfo(row){
        this.form = row
        this.dialogFormVisible = true
      },
      cancel(){
        this.dialogFormVisible = false
        this.load()
      },
      handleSelectionChange(val){
        this.multipleSelection = val
      },
      delUserInfo(id){
        userDel(id).then(res =>{
          if(res.code === 200){
            this.$message.success("删除成功！")
            this.dialogFormVisible = false
            this.form = {}
            this.load()
          }else{
            this.$message.success("删除失败！")
          }
        })
      },
      searchusers(){
        this.load()
      },
      openadd(){
        this.dialogFormVisible = true
        this.form = {}
      },
      rset(){
        this.username = ""
        this.load()
      },
      handleSizeChange(pageSize){
        this.pageSize = pageSize
        this.load()
      },
      handleCurrentChange(pageNum){
        this.pageNum = pageNum
        this.load()
      },

    }
  }
</script>

<style scoped>
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }

  .el-aside {
    color: #333;
  }
  .headerBg{
    background-color: #eee !important;
  }
</style>
