import request from '@/utils/request'

export function getUser(pageNum, pageSize, username) {
    return request({
        url: 'user/page',
        method: 'get',
        params:{
            pageNum:pageNum,
            pageSize:pageSize,
            username:username
        }
    })
}
export function userSave(data) {
    return request({
        url: 'user/saveOrUpdate',
        method: 'post',
        data
    })
}

export function userDel(id) {
    return request({
        url: 'user/del/'+id,
        method: 'delete',
    })
}
