import Vue from 'vue'
import VueRouter from 'vue-router'
import Manage from '../views/Manage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'manage',
    component: Manage,
    redirect: "/home",
    children:[
      {
        path: 'home', name: 'Home', component: () => import( '../views/Home.vue')
      },
      {
        path: 'user', name: 'User', component: () => import( '../views/User.vue')
      },
      {
        path: 'amazonShop', name: 'AmazonShop', component: () => import( '../views/AmazonShop.vue')
      },
      {
        path: 'amazonProduct', name: 'AmazonProduct', component: () => import( '../views/AmazonProduct.vue')
      },
      {
        path: 'amazonNotify', name: 'AmazonNotify', component: () => import( '../views/AmazonNotify.vue')

      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( '../views/Register.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
